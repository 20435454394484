
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {CompanySelect, LoadPanel, SearchEntityEvent, TagSelect} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import Cards from "@/components/base/common/Cards.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {useStore} from "vuex";
import {INSURED_TYPE, OPERATOR, POLICY_STATUS, SEARCH_ENTITY} from "@/core/config/Constant"
import QuickAction from "@/components/base/action/QuickAction.vue";
import {IPolicy} from "@/core/entity/IPolicy";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import PolicyViewCard from "@/views/policy/PolicyViewCard.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import PolicyRemoveSearchService from "@/core/services/PolicyRemoteSearchService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import RemotePolicyCard from "@/views/policy/RemotePolicyCard.vue";
import {useToast} from "vue-toast-notification";
import {client} from "webstomp-client";
import BHSIViewCard from "@/views/policy/BHSIViewCard.vue";


export default defineComponent({
  name: "Policies",
  components: {
    BHSIViewCard,
    RemotePolicyCard,
    EntityLoading,
    BaseRadio,
    DateTimeSelect,
    BaseSelect,
    PolicyViewCard,
    DateTimeFormat,
    QuickAction, KtDatatable, Cards, Entities
  },
  setup() {
    const remoteLoading = ref(false);
    const searchType = ref('LOCAL');
    const clientCode = ref('')
    const store = useStore();
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Policy Search',
        [
          {link: false, router: '', text: 'Policy Search'}
        ]
      )
    })
    const remoteData = ref<any>([]);
    const policyField = ref([
      {column: 'clientId', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Client', show: true},
      {column: 'searchType', type: 'RADIO', operator: OPERATOR.LIKE, value: '', name: '', show: false},
      {column: 'policyNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Policy#', show: true},
      {column: 'certificate', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Certificate', show: true},
      {column: 'firstName', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'First Name', show: true},
      {column: 'lastName', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Last Name', show: true},
      {column: 'policyType', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Type', show: true},
      {column: 'policyStatus', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Status', show: true},
      {column: 'effectiveDt', type: 'DATE', operator: OPERATOR.GT, value: '', name: 'Effective Date', show: true},
      {column: 'terminationDt', type: 'DATE', operator: OPERATOR.LS, value: '', name: 'Termination Date', show: true},
      {column: 'tags', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Tag', show: true},
    ])
    const fields = ['all']
    const policyViewPage = computed(() => store.state.PolicyModule.policyViewPage)
    const policy = computed<IPolicy>(() => store.state.PolicyModule.policy)
    const tableHeader = ref([
      {
        name: "Client",
        key: "client",
        sortable: true,
      },
      {
        name: "Product",
        key: "product",
        sortable: true,
      },
      {
        name: "Policy#",
        key: "number",
        sortable: true,
      },
      {
        name: "Certificate",
        key: "certificate",
        sortable: true,
      },
      {
        name: "Insured",
        key: "insured",
        sortable: true,
      },
      {
        name: "DOB",
        key: "dob",
        sortable: true,
      },
      {
        name: "Effective",
        key: "effectiveDate",
        sortable: true,
      },
      {
        name: "Termination",
        key: "terminationDate",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);
    return {
      searchType,
      clientCode,
      policyField,
      ...SearchEntityEvent(Actions.POLICY_SEARCH, policyField.value, fields, [], SEARCH_ENTITY.POLICY_VIEW),
      tableHeader,
      policyViewPage,
      policy,
      ...LoadPanel(),
      ...CompanySelect(),
      ...TagSelect('POLICY'),
      POLICY_STATUS,
      INSURED_TYPE,
      remoteLoading,
      remoteData,
    }
  },
  methods: {
    client,
    typeSearchChange(event) {
      this.searchType = event;

      if (event === 'REMOTE') {
        if (this.clientCode === 'EQ') {
          this.filter.query.forEach(item => {
            if (item.column !== 'clientId' && item.column !== 'searchType' && item.column !== 'policyNumber' && item.column !== 'certificate') {
              item.show = false;
            }
          })
        }
        if (this.clientCode === 'BHSI') {
          this.filter.query.forEach(item => {
            if (item.column !== 'clientId' && item.column !== 'searchType' && item.column !== 'policyNumber') {
              item.show = false;
            }
          })
        }
      } else {
        this.filter.query.forEach(item => {
          if (item.column !== 'searchType') {
            item.show = true;
          }
        })
      }
    },
    clientChange(id) {
      const client = this.clients.data.find(item => item.id === id)
      this.clientCode = client.code
      if (client && (client.code == 'EQ' || client.code == 'BHSI')) {
        this.filter.query.forEach(item => {
          if (item.column === 'searchType') {
            item.show = true;
          }
        })

      } else {
        this.filter.query.forEach(item => {
          if (item.column === 'searchType') {
            item.show = false;
          }
        })
        this.searchType = 'LOCAL'
      }
    },
    onRemoteSearch() {

      const policyCode = this.filter.query.find(item => item.column === 'policyNumber').value;
      const certificate = this.filter.query.find(item => item.column === 'certificate').value;
      if (!policyCode) {
        useToast().warning('Please input policy#', {position: 'top-right'})
        return
      }
      if (this.clientCode == 'EQ') {
        if (!certificate) {
          useToast().warning('Please input certificate', {position: 'top-right'})
          return
        }
      }
      this.remoteLoading = true;
      PolicyRemoveSearchService.findAll({key: {clientCode: this.clientCode, policyCode: policyCode, certificate: certificate}}).then(res => {
        if (res) {
          this.remoteData = res;
        } else {
          this.remoteData = [];
        }
      }).finally(() => {
        this.remoteLoading = false;
      })
    }
  }
})
