import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body pt-0 pb-0 p-5" }
const _hoisted_5 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RowCol = _resolveComponent("RowCol")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", {
          class: "cursor-pointer hover link-primary",
          "data-target": "click",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadData( _ctx.view ,'POLICY_REMOTE_BHSI')))
        }, _toDisplayString(_ctx.view.policy.policyNumber), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_RowCol, {
          label: "Insured",
          type: "CUSTOM",
          "show-if-null": true,
          value: ""
        }, {
          custom: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.view.client?.firstName) + _toDisplayString(" ") + _toDisplayString(_ctx.view.client?.surname), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_RowCol, {
          label: "DOB",
          value: _ctx.view?.client?.birthDate,
          type: "DATE"
        }, null, 8, ["value"]),
        _createVNode(_component_RowCol, {
          label: "Effective",
          value: _ctx.view.policy.startDate,
          "show-if-null": true,
          type: "DATE"
        }, null, 8, ["value"]),
        _createVNode(_component_RowCol, {
          label: "Termination",
          value: _ctx.view.policy?.endDate,
          "show-if-null": true,
          type: "DATE"
        }, null, 8, ["value"])
      ])
    ])
  ]))
}